import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('WarningAllLK'), _c('WarningRepricer'), _c('has-tokens', [!_vm.loading ? _c('report-page', [_c('main-title', {
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('div', {
          staticClass: "highlights__filters"
        }, [_c('button', {
          staticClass: "btn-outline btn-fit",
          staticStyle: {
            "margin-right": "10px"
          },
          on: {
            "click": function ($event) {
              return _vm.$router.push({
                name: 'Connections'
              });
            }
          }
        }, [_vm._v(" Управление магазинами "), _c('img', {
          attrs: {
            "src": require(`@/assets/images/icons/edit.svg`),
            "width": "19",
            "height": "18"
          }
        })]), _vm.hasOzon && _vm.hasWb ? _c('app-select', {
          staticClass: "highlights__select",
          attrs: {
            "items": _vm.select_marketplace_items,
            "fullwidth": ""
          },
          model: {
            value: _vm.marketplace,
            callback: function ($$v) {
              _vm.marketplace = $$v;
            },
            expression: "marketplace"
          }
        }) : _vm._e(), _c('app-select-multi', {
          staticClass: "highlights__select",
          attrs: {
            "items": _vm.select_tokens_items
          },
          model: {
            value: _vm.selectedTokens,
            callback: function ($$v) {
              _vm.selectedTokens = $$v;
            },
            expression: "selectedTokens"
          }
        }), _c('div', {
          staticClass: "report-settings"
        }, [_vm.calendar ? _c('range-calendar', {
          attrs: {
            "boundaries": _vm.$store.state.repricer.calendarBoundaries
          },
          model: {
            value: _vm.calendar,
            callback: function ($$v) {
              _vm.calendar = $$v;
            },
            expression: "calendar"
          }
        }) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }], null, false, 1185256414)
  }, [_vm._v(" Мои товары ")]), _c('div', {
    staticClass: "mt32",
    staticStyle: {
      "margin-bottom": "60px"
    }
  }, [_c('div', {
    staticClass: "table-filters"
  }, [_c('data-table-actions', {
    attrs: {
      "id": _vm.getTableId() + 1
    }
  })], 1), _vm.reportSettings ? _c('my-products-table', {
    attrs: {
      "id": _vm.getTableId() + 1,
      "report-settings": _vm.reportSettings,
      "selectable": false,
      "paginatable": true,
      "configurable": true
    }
  }) : _vm._e()], 1), _c('main-title', [_vm._v(" Товары под управлением - динамика цен ")]), _c('div', {
    staticClass: "mt32"
  }, [_c('div', {
    staticClass: "table-filters"
  }, [_c('data-table-actions', {
    attrs: {
      "id": _vm.getTableId() + 2
    }
  })], 1), _vm.reportSettings ? _c('products-management-table', {
    attrs: {
      "id": _vm.getTableId() + 2,
      "report-settings": _vm.reportSettings,
      "selectable": false,
      "paginatable": true,
      "configurable": true
    }
  }) : _vm._e()], 1)], 1) : _c('div', [_c('data-loading', {
    attrs: {
      "title": 'Сейчас мы загружаем данные вашего магазина',
      "image": require('@/assets/images/wireframe.jpg'),
      "text1": 'На период загрузки отчеты пока недоступны, обычно этот процесс занимает 4-8 часов. Попробуйте вернуться позже в этот раздел.',
      "text2": 'Также по завершении загрузки мы отправим оповещение на ваш email'
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };